import React from 'react';
import {Helmet} from 'react-helmet';
import _ from 'lodash';

import {safePrefix} from '../utils';
import Header from './Header';
import Footer from './Footer';

export default class Body extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>{_.get(this.props, 'pageContext.frontmatter.title') && _.get(this.props, 'pageContext.frontmatter.title') + ' - '}{_.get(this.props, 'pageContext.site.siteMetadata.title')}</title>
                    <meta charSet="utf-8"/>
                    <meta name="viewport" content="width=device-width, initialScale=1.0" />
                    <meta name="google" content="notranslate" />
                    <link href='https://fonts.googleapis.com/css?family=Oswald' rel='stylesheet'></link>
                    <link href='https://fonts.googleapis.com/css?family=Montserrat' rel='stylesheet'></link>
                    <link href='https://fonts.googleapis.com/css?family=Lato' rel='stylesheet'></link>
                    <link rel="stylesheet" href={safePrefix('assets/css/main.css')}/>
                    <link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet"/>
                    <script src="https://assets.calendly.com/assets/external/widget.js" type="text/javascript"></script>
                    <link rel="apple-touch-icon" sizes="57x57" href="favicons/apple-icon-57x57.png"></link>
                    <link rel="apple-touch-icon" sizes="60x60" href="favicons/apple-icon-60x60.png"></link>
                    <link rel="apple-touch-icon" sizes="72x72" href="favicons/apple-icon-72x72.png"></link>
                    <link rel="apple-touch-icon" sizes="76x76" href="favicons/apple-icon-76x76.png"></link>
                    <link rel="apple-touch-icon" sizes="114x114" href="favicons/apple-icon-114x114.png"></link>
                    <link rel="apple-touch-icon" sizes="120x120" href="favicons/apple-icon-120x120.png"></link>
                    <link rel="apple-touch-icon" sizes="144x144" href="favicons/apple-icon-144x144.png"></link>
                    <link rel="apple-touch-icon" sizes="152x152" href="favicons/apple-icon-152x152.png"></link>
                    <link rel="apple-touch-icon" sizes="180x180" href="favicons/apple-icon-180x180.png"></link>
                    <link rel="icon" type="image/png" sizes="192x192"  href="favicons/android-icon-192x192.png"></link>
                    <link rel="icon" type="image/png" sizes="32x32" href="favicons/favicon-32x32.png"></link>
                    <link rel="icon" type="image/png" sizes="96x96" href="favicons/favicon-96x96.png"></link>
                    <link rel="icon" type="image/png" sizes="16x16" href="favicons/favicon-16x16.png"></link>
                    <link rel="manifest" href="favicons/manifest.json"></link>
                    <meta name="msapplication-TileColor" content="#ffffff"></meta>
                    <meta name="msapplication-TileImage" content="favicons/ms-icon-144x144.png"></meta>
                    <meta name="theme-color" content="#ffffff"></meta>
                </Helmet>
                {/* <div id="page" className={'site palette-' + _.get(this.props, 'pageContext.site.siteMetadata.palette')}> */}
                  <div id="page" className="site">  
                  <Header {...this.props} />
                  <main id="content" className="site-content">
                    {this.props.children}
                  </main>
                  <Footer {...this.props} />
                </div>
            </React.Fragment>
        );
    }
}
