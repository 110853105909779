import React from 'react';
import _ from 'lodash';

import {safePrefix, markdownify, Link} from '../utils';
import CtaCalendly from './CtaCalendly';

export default class SectionHero extends React.Component {
    render() {
        return (
            <section id={_.get(this.props, 'section.section_id')} className={'block hero-block outer bg-' + _.get(this.props, 'section.bg')}>
              <div className="inner">
                <div className="grid">
                  {_.get(this.props, 'section.image') && 
                  <div className={'cell block-preview ' + _.get(this.props, 'section.image_position')}>
                    <img src={safePrefix(_.get(this.props, 'section.image'))} alt={_.get(this.props, 'section.title')} />
                  </div>
                  }
                  <div className="cell block-content">
                    {_.get(this.props, 'section.title') && 
                    <h2 className="block-title">{_.get(this.props, 'section.title')}</h2>
                    }
                    <div className="block-copy">
                      {markdownify(_.get(this.props, 'section.content'))}
                    </div>
                    {_.get(this.props, 'section.calendly') && 
                    <CtaCalendly {...this.props} actions={_.get(this.props, 'section.calendly')} />
                    }
                    {_.get(this.props, 'section.actions') && 
                    <p className="block-buttons">
                      {_.map(_.get(this.props, 'section.actions'), (action, action_idx) => (
                      <Link key={action_idx} to={safePrefix(_.get(action, 'url'))} data-formkit-toggle="802d2c936d" className="button black large">{_.get(action, 'label')}</Link>
                      ))}
                    </p>
                    }
                  </div>
                </div>
              </div>
            </section>
        );
    }
}
