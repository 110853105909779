import React from 'react';
import _ from 'lodash';

export default class CtaCalendly extends React.Component {
    render() {
      return (
        <div className="block-buttons">
          {_.map(_.get(this.props, 'actions'), (action, action_idx) => (
          <a key={action_idx} id="calendly" data-url={_.get(action, 'url')} className="button black large">{_.get(action, 'label')}</a>
          ))}
        </div>
    );
    }
}
