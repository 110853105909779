import React from 'react';
import _ from 'lodash';

import {safePrefix, htmlToReact, markdownify, Link} from '../utils';
import CtaCalendly from './CtaCalendly';

export default class SectionSteps extends React.Component {
    render() {
        return (
            <section id={_.get(this.props, 'section.section_id')} className={'block pricing-block bg-' + _.get(this.props, 'section.bg') + ' outer'}>
              <div className="block-header inner-small">
                {_.get(this.props, 'section.title') && 
                <h2 className="block-title-steps">{_.get(this.props, 'section.title')}</h2>
                }
              </div>
              {_.get(this.props, 'section.steps') && 
              <div className="inner">
                <div className="grid">
                  {_.map(_.get(this.props, 'section.steps'), (step, step_idx) => (
                  <div key={step_idx} className="cell-step step">
                    <div className="step-inside">
                    <img src={safePrefix(_.get(step, 'image'))} alt={_.get(step, 'title')} />  
                    <h3 className="step-name">{_.get(step, 'title')}</h3>
                    {_.get(step, 'content') && 
                    <div className="step-content">{_.get(step, 'content')}</div>
                    }
                    </div>
                  </div>
                  ))}
                </div>
              </div>
              }
              {_.get(this.props, 'section.actions') && 
                <p className="block-buttons center">
                  {_.map(_.get(this.props, 'section.actions'), (action, action_idx) => (
                  <Link key={action_idx} to={safePrefix(_.get(action, 'url'))} className="button black large">{_.get(action, 'label')}</Link>
                  ))}
                </p>
              }
              <div className="center">
              {_.get(this.props, 'section.calendly') && 
                <CtaCalendly {...this.props} actions={_.get(this.props, 'section.calendly')} />
              }
              </div>
            </section>
        );
    }
}
